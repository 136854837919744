import { StopType } from 'shared/enums/stop-type.enum';
import { UnitOfMeasure } from 'shared/enums/unit-of-measure.enum';
import { SpecializedEquipmentType } from 'shared/enums/specialized-equipment-type.enum';
import { Offer } from 'shared/models/offers/offer.model';
import { BaseModel } from '../base.model';
import { Stop } from './stops/stop.model';
import { LoadContact } from '../contacts/load-contact.model';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { Temperature } from '../my-loads/measurements/temperature.model';
import { getLoadPickDate } from 'app/util/loads/pick-drop-date-calculator';
import { AutoOpenSchedule } from 'shared/models/loads/auto-open/auto-open-schedule.model';
import { AUTO_OPEN_SCHEDULE_ACTION } from 'app/globals/constants';

/* tslint:disable-next-line:max-line-length */
/** @see https://github.com/ch-robinson-internal/NavisphereCarrier.AvailableLoadsAPI/blob/development/src/NavisphereCarrier.AvailableLoads.Domain/AvailableLoads/AvailableLoadDetail.cs */

export class AvailableLoadDetail extends BaseModel<AvailableLoadDetailJSON> {
  number: number;
  status: number;
  mode: string;
  stopDetails: Array<Stop>;
  contacts: Array<LoadContact>;
  currencyCode: string;
  weight: number;
  unitOfMeasure: UnitOfMeasure;
  loadValue: number;
  distance: number;
  equipment: Equipment;
  isRegulatedBySTF: boolean;
  isTeamLoad: boolean;
  isHazMat: boolean;
  isDropTrailer: boolean;
  isWeightTickets: boolean;
  isTankerEndorsementRequired: boolean;
  specializedEquipmentCode: SpecializedEquipmentType;
  binRateCost: BinRateCost;
  totalCost: number;
  rateCurrencyCode: string;
  hasRateObject: any;
  isLocked: boolean;
  items: AvailableLoadItemJSON[];
  rating: string;
  tarpType: any;
  strapsCount: number;
  chainsCount: number;
  coilRacks: number;
  readyDate: Date;
  deliveryAvailableDate: Date;
  pickUpByDate: Date;
  activityDate: Date;
  offers: Offer[];
  shipmentNotes: shipmentNoteJSON[]
  maxTemp: Temperature;
  minTemp: Temperature;
  carrierTier: string;
  isWebExclusive?: boolean;
  autoOpenSchedules?: AutoOpenSchedule[];
  private firstPick: Stop;
  private lastDrop: Stop;
  private _pickStartTimeV2: Date;
  private _pickEndTimeV2: Date;
  get isRegulatedByStf() {
    return this.isRegulatedBySTF;
  }
  set isRegulatedByStf(value: boolean) {
    this.isRegulatedBySTF = value;
  }

  hasEndorsement() {
    return this.isRegulatedBySTF || this.isTankerEndorsementRequired || this.isHazMat;
  }

  constructor(data: AvailableLoadDetailJSON) {
    super(data);
    // fix unitOfMeasure property name from API
    this.unitOfMeasure = data.measurementType;

    this.readyDate = this.processDate(data.readyDate);
    this.deliveryAvailableDate = this.processDate(data.deliveryAvailableDate);
    this.pickUpByDate = this.processDate(data.pickUpByDate);
    this.activityDate = this.processDate(data.activityDate);
    this.hasRateObject = this.binRateCost;

    if (this.hasRateObject) {
      this.rateCurrencyCode = this.binRateCost.currencyCode;
      this.totalCost = this.binRateCost.totalCost;
    }

    if (data.offers) {
      this.offers = data.offers.map(offer => new Offer(offer));
    }

    if (data.stopDetails) {
      this.stopDetails = data.stopDetails.map(stop => new Stop(stop)).slice().sort((a, b) => a.number - b.number);
    }

    if (data.autoOpenSchedules) {
      this.autoOpenSchedules = data.autoOpenSchedules.map(autoOpenSchedule => new AutoOpenSchedule(autoOpenSchedule))
    }
  }

  getLastDrop(): Stop {
    if (!this.stopDetails) {
      return new Stop();
    }

    if (!this.lastDrop) {
      const sortedStops = this.stopDetails.slice().sort((a, b) => a.number - b.number);
      this.lastDrop = sortedStops.reverse().find(stop => stop.stopType === StopType.DROP_OFF);
    }

    return this.lastDrop;
  }

  getFirstPick(): Stop {
    if (!this.stopDetails) {
      return new Stop();
    }

    if (!this.firstPick) {
      const sortedStops = this.stopDetails.slice().sort((a, b) => a.number - b.number);
      this.firstPick = sortedStops.find(stop => stop.stopType === StopType.PICKUP);
    }

    return this.firstPick;
  }

  getTotalWeight() {
    if (Array.isArray(this.items)) {
      return this.items.reduce((sum, commodity) => (sum + commodity.weight), 0);
    }

    return 0;
  }

  get activeOffer() {
    return this.offers?.length && this.offers.find(offer => offer.open);
  }

  get pickStartTimeV2(): Date {
    const stop = this.getFirstPick();
    const apptType = stop?.appointmentType;
    const arriveByDate = this.processDate(stop.calculatedArriveByStartDateTime);
    const warehouseOpeningTime = this.processTime(stop.warehouse.openingDateTime);
    this._pickStartTimeV2 = getLoadPickDate(arriveByDate, this.activityDate, apptType, warehouseOpeningTime);
    return this._pickStartTimeV2;
  }

  get pickEndTimeV2(): Date {
    const stop = this.getFirstPick();
    const apptType = stop?.appointmentType;
    const arriveByDate = this.processDate(stop.calculatedArriveByEndDateTime);
    const warehouseClosingTime = this.processTime(stop.warehouse.closingDateTime);
    this._pickEndTimeV2 = getLoadPickDate(arriveByDate, this.activityDate, apptType, warehouseClosingTime);
    return this._pickEndTimeV2;
  }

  get openToCoreDateTime(): Date {
    return this.autoOpenSchedules?.filter(schedule => schedule.action == AUTO_OPEN_SCHEDULE_ACTION && schedule.newValue == "10")[0]?.scheduledDateTimeUtc;
  }

  get openToBaseDateTime(): Date {
    return this.autoOpenSchedules?.filter(schedule => schedule.action == AUTO_OPEN_SCHEDULE_ACTION && schedule.newValue == "0")[0]?.scheduledDateTimeUtc;
  }
}

export class AvailableLoadNotFound extends AvailableLoadDetail {
  public constructor(public number: number) {
    super({ number } as any);
  }
}

export class AvailableLoadError extends AvailableLoadDetail {
  public constructor(public error: AjaxError, public number: number) {
    super({ number } as any);
  }
}
